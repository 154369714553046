import { FINANCIAL, WALLET_PATHS } from '@constants/index'

const defaultCoin = 'BTC'
const pathnameEum = {
  deposit: WALLET_PATHS.DEPOSIT.replace('[coin]', defaultCoin),
  withdrawal: WALLET_PATHS.WITHDRAWAL.replace('[coin]', defaultCoin),
  history: WALLET_PATHS.HISTORY_REWARD,
  financeAccount: WALLET_PATHS.FINANCE_OVERVIEW,
  tradeAccount: WALLET_PATHS.TRADE_OVERVIEW,
  c2cAccount: WALLET_PATHS.C2C_ACCOUNT,
  flexibleAndFixed: FINANCIAL.REGULAR_AND_FLEXIBLE,
  regularInvestment: FINANCIAL.AIP
}

export default pathnameEum
